import { STANDART_WIDTH } from '../aboutContainer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

const AboutContainerReviews = () => {
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <div style={{
      width: matches ? '100%' : STANDART_WIDTH,
      height: '800px',
      overflow: 'hidden',
      position: 'relative',
      marginTop: '30px',
    }}
    >
      <iframe
        title="Наши отзывы"
        style={{
          width: '100%',
          height: '100%',
          border: '1px solid #eaeaea',
          borderRadius: '8px',
          boxSizing: 'border-box',
        }}
        src="https://yandex.ru/maps-reviews-widget/111242443235?comments"
      />
      <a
        href="https://yandex.ru/maps/org/khochu_sushi/111242443235/"
        target="_blank"
        rel="noreferrer"
        style={{
          boxSizing: 'border-box',
          textDecoration: 'none',
          color: '#b3b3b3',
          fontSize: '10px',
          fontFamily: 'YS Text,sans-serif',
          padding: '0 20px',
          position: 'absolute',
          bottom: '8px',
          width: '100%',
          textAlign: 'center',
          left: 0,
        }}
      >
        Хочу суши на карте Севастополя — Яндекс.Карты
      </a>
    </div>
  );
};

export default AboutContainerReviews;
