import AboutContainerReviews from './AboutContainerReviews/aboutContainerReviews';
import { AboutContainerStyle, AboutContainerPara, AboutContainerH2 } from './aboutContainer.module.css';
import Paragraph from '../Blocks/Paragraph/paragraph';
import H2 from '../Blocks/H2/H2';
import FooterBottomSocial from '../Footer/FooterBottom/FooterBottomSocial/footerBottomSocial';
import { vkPixelFindLocation } from '../../Utils/vkPixel';
import React, { useEffect } from 'react';

export const STANDART_WIDTH = '800px';

const AboutContainer = ({ aboutText }) => {
  useEffect(() => {
    vkPixelFindLocation();
  }, []);

  return (
    <div className={AboutContainerStyle}>
      <H2 className={AboutContainerH2}>О нас</H2>
      <div style={{ maxWidth: '800px' }}>
        <Paragraph className={AboutContainerPara}>{aboutText}</Paragraph>
      </div>
      <FooterBottomSocial />
      <AboutContainerReviews />
    </div>
  );
};

export default AboutContainer;
