import Layout from '../components/layout';
import SEO from '../components/seo';
import AboutContainer from '../components/AboutContainer/aboutContainer';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const AboutPage = ({ location }) => {
  const data = useStaticQuery(graphql`{
    strapiOrganization {
      AboutUs
    }
  }`);

  return (
    <Layout location={location} headerIsStatic>
      <SEO title="О нас" description="10 лет назад, компания молодых и трудолюбивых людей решила объединиться и создать доставку с незамысловатым названием 'Хочу Суши'" />
      <AboutContainer aboutText={data.strapiOrganization.AboutUs}/>
    </Layout>
  );
};

export default AboutPage;
